<template>
  <nav id="navbar" class="navbar navbar-expand-lg zero-bg">
    <div class="container-fluid container-xl">
      <router-link to="/" class="navbar-brand">
        <img class="navbar-logo" src="@/assets/images/logo.png" alt="kickoff-logo" width="100" style="z-index: 999;">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-nav ms-auto d-none d-lg-flex">
        <router-link v-for="link in links" :key="link" :to="link.link" class="nav-link">
          <template v-if="!link.isNotKey">
            <span v-html="$t(link.name)"></span>
          </template>
          <template v-else>
            <b><i v-html="link.name" style="color:white"></i></b>
          </template>
        </router-link>         
      </div>
      <div class="d-flex align-items-center">
        <div data-bs-toggle="modal" data-bs-target="#modal-lang-select" style="margin-left: 15px; cursor: pointer;">
          <img v-if="activeLang" :src="activeLang.img" :alt="activeLang.txtMin" width="25">
        </div>
        <div class="burger d-block d-lg-none ms-3" @click="openMobileNav()">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </nav>
  <MobileNav 
    :links='links'
    @closeMobileNav="closeMobileNav" />
  <LangSelect 
    :langs="langs"
    @switchLocale="switchLocale" />
</template>

<script setup>
  import { ref, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n';
  import { useRoute } from 'vue-router';

  import menuData from '@/composables/menuData.js'
  import MobileNav from '@/components/mobileNav.vue'
  import LangSelect from '@/components/modal/langSelect'
  const { locale, availableLocales } = useI18n()

  const links = menuData

  const langs = ref([
      { lng: 'cs', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/cz.png', txt: 'Česko', txtMin: 'Čeština', /* txtKey: '' */ },
      { lng: 'sk', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/sk.png', txt: 'Slovensko', txtMin: 'Slovenčina', /* txtKey: '' */ },
      { lng: 'it', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/it.png', txt: 'Italia', txtMin: 'Italiano', /* txtKey: '' */ },
      { lng: 'en', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/uk.png', txt: 'United Kingdom', txtMin: 'English', /* txtKey: '' */ },
      { lng: 'es', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/es.png', txt: 'España',  txtMin: 'Español',/* txtKey: '' */ },
      { lng: 'ru', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/ru.png', txt: 'Россия',  txtMin: 'Русский',/* txtKey: '' */ },
      { lng: 'de', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/de.png', txt: 'Deutschland',  txtMin: 'Deutsch',/* txtKey: '' */ },
      { lng: 'fr', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/fr.png', txt: 'France',  txtMin: 'Français',/* txtKey: '' */ },
      { lng: 'gr', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/gr.png', txt: 'Ελλάδα',  txtMin: 'ελληνικά',/* txtKey: '' */ },
      { lng: 'si', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/si.png', txt: 'Slovenija',  txtMin: 'Slovenščina',/* txtKey: '' */ },
      { lng: 'pl', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/pl.png', txt: 'Polska',  txtMin: 'Polski',/* txtKey: '' */ },
      { lng: 'uk', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/ua.png', txt: 'Ukraine',  txtMin: 'Українська',/* txtKey: '' */ },
      { lng: 'lt', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/lt.png', txt: 'Lithuania',  txtMin: 'Lietuvių',/* txtKey: '' */ },
  ])

  const activeLang = ref({/* lng: 'cs', isActive: false, img: 'https://www.essenskickoff.com/2022/img/flag/cz.png', txt: 'Česko', txtMin: 'Čeština', */})

  function switchLocale(localeee) {
      locale.value = localeee

      langs.value.filter(lang => {
        lang.isActive = false
        if(lang.lng == localeee) {
          lang.isActive = true
          switchLang(lang)
          console.log('lang', lang)
        }
      });
  }

  function switchLang(lng) {
    activeLang.value = lng
  }

  const route = ref(useRoute())

  watch(route.value, (to, from) => {
    console.log('watch route TO', to)
    console.log('to', to.fullPath)
    if(to.fullPath !== '/') {
      addNavbarBg()
    } else {
      changeNavbarBg()
    }
  })

  window.onscroll = () => {
    changeNavbarBg()
  }

  function changeNavbarBg() {
    if(window.pageYOffset > 0) {
      addNavbarBg()
    } else {
      if(window.location.pathname !== '/') { return }
      /* console.log('onscroll kokoooooot') */
      removeNavbarBg()
    }
  }

  function addNavbarBg() {
    const navbar = document.getElementById('navbar')
    navbar.classList.remove('zero-bg')
  }

  function removeNavbarBg() {
    const navbar = document.getElementById('navbar')
    navbar.classList.add('zero-bg')
  }

  function openMobileNav() {
    const mobileNav = document.getElementById('mobile-nav')
    const overlay = document.querySelector('.overlay')
    mobileNav.classList.remove('closed')
    overlay.classList.remove('d-none')
  }

  function closeMobileNav() {
    const mobileNav = document.getElementById('mobile-nav')
    const overlay = document.querySelector('.overlay')
    mobileNav.classList.add('closed')
    overlay.classList.add('d-none')
  }

  function switchLocaleIfInArray(lang) {
    const object = langs.value.find(obj => obj.lng === lang)
    /* console.log('navbar getObjectByLng.. lang', lang)
    console.log('navbar getObjectByLng.. object', object) */
    if(object) {
      console.log('navbar getObjectByLng.. prošlo', lang)
      switchLocale(object.lng)
    } else {
      switchLocale('en')
    }
  }

  onMounted(() => {
    /* get activeLanguage by browser */
    let browserLng = navigator.language
    /* console.log('navbar.. navigator.language:', navigator.language) */
    if(browserLng.includes('-')) {
      browserLng = browserLng.slice(0, browserLng.indexOf("-"))
    }
    
    switchLocaleIfInArray(browserLng)
  })
</script>

<style>
  .burger {
    cursor: pointer;
  }
  
  .burger > * {
    margin: 5px;
    height: 3px;
    width: 25px;
    background-color: white;
  }
</style>
<template>
    <ModalDefault
        htmlId="modal-lang-select"
        classes="not-round not-borders"
        modalSize="modal-lg"
    >
        <slot>
            <h3 class="modal-title text-center" id="country-select-label" style="margin-bottom: 25px;">{{ $t('turkeyVyberJaz') }}</h3>
            <div class="container-fluid">
                <div class="row modal-lang">
                    <div v-for="lang in langs" :key="lang.lng"
                        @click='switchLocale(lang.lng)' data-bs-dismiss="modal"
                        class="lang col-6 col-sm-4 col-lg-3" style="margin: 15px 0;">
                        <div class="mod-flag-wrap">
                            <div class="mod-flag-img">
                                <img class="mod-flag" :src="lang.img" :alt="lang.txt">
                            </div>
                            <div class="mod-flag-t">
                                <span class="mod-flag-th">{{ lang.txt }}</span>
                                <span class="mod-flag-tht">{{ lang.txtMin }}</span>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </slot>
        <template v-slot:footer>
            <button type="button" class="btn btn-primary" 
                style="min-width: 180px; height: 50px;" data-bs-dismiss="modal">
                {{ $t('turkeyClose') }}
            </button>
        </template>
    </ModalDefault>
</template>

<script setup>
    import ModalDefault from './default.vue'
    /* import i18n from '../../locales/i18n.js' */

    const props = defineProps({
        langs: Array
    });

    const emit = defineEmits(['switchLocale']);

    function switchLocale(locale) {
        emit('switchLocale', locale)
    }
</script>

<style>
    .lang {
        position: relative;
    }

    .mod-flag-wrap {
        display: flex; 
        padding-left: 10px;
        cursor: pointer;
    }

    .mod-flag-img {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .mod-flag-th {
        font-weight: 600; 
        display: flex; 
        width: 100%;
    }

     .mod-flag-tht {
        display: flex;
        flex-wrap: wrap;
    }

    .mod-flag-t {
        display: inline-flex; 
        flex-wrap: wrap; 
        align-items: center; 
        justify-content: flex-start; 
        align-content: center;
        padding-left: 10px;
    }

    @media (max-width: 350px) {
        .mod-flag-wrap {
            padding: 0;
            justify-content: center;
        }

        .mod-flag {
            width: 44px;
        }

        .mod-flag-t {
            display: none;
        }
    }


    @media (min-width: 350px) {
        .mod-flag {
            width: 44px;
        }

        .mod-flag-t {
        
            font-size: 10px;
            color: black;
        
        }
    }


    @media (min-width: 767px) {
        .mod-flag {
            width: 40px;
        }

        .mod-flag-t {
        
            font-size: 10px;
            color: black;
        
        }
    }


    @media (min-width: 991px) {
        .mod-flag {
            width: 50px;
        }

        .mod-flag-t {
        
            font-size: 12px;
            color: black;
        
        }
    }
</style>
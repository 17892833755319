const menuData = [
    { name: 'webHome', link: '/' },
    { name: 'webEvent', link: '/#about-event' },
    { name: 'turkeyPr', link: '/#program' },
    { name: 'webGallery', link: '/#photo-gallery' },
    { name: 'turkeyVideo', link: '/#after-movies' },

    /* { isNotKey: true, name: 'Test swiper', link: '/test_swiper' },
    { isNotKey: true, name: 'gallery UK', link: '/gallery/uk' }, */
]
export default menuData
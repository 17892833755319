<template>
    <section id="photo-gallery">
        <div class="container container-xl-fluid">
            <div class="secondary-heading text-center">ESSENS TURKEY 2023</div>
            <h1 class="text-center mb-5"> {{ $t('webGallery') }} </h1>
            <div class="row justify-content-center">
                <div v-for="gallery in galleries" :key="gallery" class="col-xl-4 col-lg-4 col-md-6 col-12 my-4 my-lg-5 px-lg-4">
                    <router-link :to="gallery.href" class="gallery-thumbnail d-block text-center">
                        <img :src="gallery.imgSrc" :alt="gallery.name + ' image'">
                        <div class="thumbnail-h ">{{ gallery.name }}</div>
                        <!-- <button class="btn btn-primary d-table mx-auto mt-3">Zobrazit</button> -->
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>

    const galleries = [
        { name: 'PAPILLON ZEUGMA RELAXURY HOTEL', href: '/gallery/hotel', imgSrc: 'https://static.essensworld.com/images/turkey_23/web/hotel.jpg' },
        { name: 'SELUZ & ERTE COSMETICS', href: '/gallery/se' , imgSrc: 'https://static.essensworld.com/images/turkey_23/web/se.jpg'},
    ]
</script>

<style>
    .thumbnail-h {
        color: var(--primary);
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        padding-top: 20px;
    }

    .gallery-thumbnail {
        /* border: 1px solid var(--secondary); */
    }
</style>
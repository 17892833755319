<script>
import { Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';
/* Lazy load imgs */
  import VLazyImage from "v-lazy-image";

export default {
  components: {
    Swiper, 
    SwiperSlide,
    VLazyImage
  },
  props: {
    currentSlide: Number,
    images: Array
  },
  emits: ['thumbs', 'update:currentSlide'],
  setup(props, { emit }) {
    const onSwiper = (swiper) => {
      emit('thumbs', swiper)
    }

    return {
      onSwiper,
      Thumbs,
      images: props.images,
      VLazyImage,
    }
  }
}
</script>

<template>
  <Swiper @swiper="onSwiper" :slides-per-view="'auto'" :space-between="15" :modules="[Thumbs]" watch-slides-progress>
    <SwiperSlide v-for="img in images" :key="img" @click.stop="$emit('update:currentSlide', Number(img.id))">
      <div class="lightbox-thumbnail-img-wrap">
        <v-lazy-image :src="img.url" src-placeholder="https://cdn-images-1.medium.com/max/80/1*xjGrvQSXvj72W4zD6IWzfg.jpeg" alt="img" />
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<style scoped>
  img {
    width: 100%;
  }

  .lightbox-thumbnail-img-wrap img {
    object-fit: cover;
    height: 70px;
  }
</style>